import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectCard from '../components/projectCard'
import { Blob2 } from '../components/blobs'
import { Parallax } from 'react-scroll-parallax';
import { motion } from "framer-motion"

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMdx

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5
      }
    }
  }
  
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  return (
    <Layout>
      <SEO title="eskargeaux" />
      <h1 className="sr-only">eskargeaux</h1>
      <div className="relative">
        <Parallax className="md:block absolute top-0 left-0 hidden w-full h-full" y={['-100px', '100px']} tagOuter="div">
          <Blob2 right="-200px" top="0px" color="stone"/>
        </Parallax>
        <p className="dark-mode:text-neige text-bleu md:text-5xl md:mb-20 md:mt-0 md:px-0 relative z-10 max-w-2xl px-8 py-20 mx-auto mt-10 mb-10 font-serif text-4xl italic font-bold leading-tight text-center">
          A Web multi-instrumentalist focused on building performant, beautiful,
          delightful projects.
        </p>
      </div>
      <motion.ul className="list-style-none margin-none project_list max-w-full pb-10" variants={container} initial="hidden" animate="show">
        {posts.map(({ node: post }, i) => (
          <motion.li variants={item}>
          <ProjectCard
            i={i + 1}
            img={post.frontmatter.featuredImg}
            key={post.id}
            slug={post.frontmatter.slug}
            title={post.frontmatter.title}
            excerpt={post.frontmatter.excerpt}
            />
          </motion.li>
        ))}
      </motion.ul>
      <div className="md:mb-20 md:p-20 p-6 mt-0 mb-16 text-center">
        <blockquote className="md:text-5xl dark-mode:text-neige text-bleu mb-5 font-serif text-4xl italic leading-tight">
          “Recognizing the need is the primary condition for design.”
        </blockquote>
        <cite className="dark-mode:text-stone font-serif text-lg not-italic">Charles Eames</cite>
      </div>
      <div className="md:p-20 flex flex-col items-center justify-center p-8">
        <div className="md:flex-row md:items-center flex flex-col">
          <div className="md:w-1/4">
            <h4 className="dark-mode:text-stone text-bleu md:mb-0 md:pr-8 mb-8 font-sans text-2xl">Other Select Clients</h4>
          </div>
          <div className="md:w-3/4">
            <ul className="text-bleu text-columns font-serif text-2xl">
              <li>Viacom</li>
              <li>Vispero</li>
              <li>The Century Foundation</li>
              <li>Two Blind Brothers</li>
              <li>The Lip Bar</li>
              <li>Mason Interactive</li>
              <li>MDSolarSciences</li>
              <li>Mother Dirt</li>
              <li>Rucker Roots</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogIndex {
    allMdx(filter: {frontmatter: {featured: {eq: true}}}, sort: {fields: frontmatter___published, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            tags
            excerpt
            featuredImg {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            published
            featured
          }
        }
      }
    }
  }
`

export default IndexPage
