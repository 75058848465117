import { Parallax } from 'react-scroll-parallax';
import PropTypes from 'prop-types'
import React from 'react'
import { Blob2, Blob3, BlobMask, BlobMask2 } from './blobs'
import { WavyLink } from './wavyLink'
import TransitionLink from 'gatsby-plugin-transition-link'

const ProjectCard = ({ title, slug, img, excerpt, i }) => {
    let reverse = false
    if (i%2 === 0) {
        reverse = true
    }

    return (
    <div className="block mb-20">
        <div className={`${reverse ? 'flex-col-reverse' : 'flex-col'} relative h-auto md:h-1/3 lg:h-3/4 flex md:flex-row text-bleu dark-mode:text-neige`}>

            <Parallax className="md:block absolute top-0 left-0 z-0 hidden w-full h-full" y={ reverse ? ['-200px', '200px'] : ['150px', '-150px']} tagOuter="div">
                {reverse ? (
                    <Blob2 right="-200px" left="unset" top="0px" color="neige"/>
                    ) : (
                        <Blob3 right="unset" left="-200px" top="0px" color="stone"/>
                        )}
            </Parallax>
            
            <div className={`h-full z-10 md:w-1/2 flex flex-col p-8 md:p-10 justify-center items-center md:items-start ${reverse ? 'order-1' : 'order-0'}`}>
                <div className="w-full max-w-xl mx-auto">
                    <h2>{title}</h2>
                    <h3>{excerpt}</h3>

                    <WavyLink slug={slug} text="view more" />

                </div>
            </div>

            <TransitionLink className="md:w-1/2 flex items-center justify-center block" to={`/${slug}`}>
                {reverse ? (
                    <BlobMask2 imgSrc={img.childImageSharp.fluid.src} />
                    ) : (
                        <BlobMask imgSrc={img.childImageSharp.fluid.src} />
                        )}
            </TransitionLink>

        </div>
    </div>
)}

ProjectCard.propTypes = {
    i: PropTypes.number,
    title: PropTypes.string,
    slug: PropTypes.string,
    img: PropTypes.object,
}

ProjectCard.defaultProps = {
    i: null,
    title: '',
    slug: '',
    img: '',
}

export default ProjectCard
